import React from "react";

import AboutImage from '../Images/About.jpg';
import Layout from '../components/layout';
import Head from '../components/head';

import {
    AboutContainer,
    Image,
    AboutDescription,
    SkillsDescribtion,
    SkillsContainer,
    SkillsColumn
} from '../Styles/About.style';

const About = () => {
    return (
        <Layout>
            <Head title="About" />
            <AboutContainer>
                <Image src={AboutImage}/>
                <AboutDescription>
                    <h2>About Me</h2>
                    <p>
                        Hello World! My full name is Shaden Hakim, also known as
                        {' '}<b><a href="https://www.instagram.com/shadencodes/" target="_blank" rel="noopener noreferrer">@ShadenCodes</a></b>{' '}
                        on Instagram. I have started my career in Software Development after graduating with a B.Sc. in Computer Engineering from the
                         Technion, Israel in 2015. In addition, I earned an MBA from the Technion, specializing in big data and
                          business intelligence.<br /><br/>

                          My MBA studies provided me with a deep understanding of advanced topics 
                          such as artificial intelligence and machine learning, which have been invaluable 
                          in analyzing and interpreting complex data sets. I also had the opportunity to work on 
                          real projects and case studies, which allowed me to apply theoretical concepts to practical 
                          situations.<br /><br/>
                        
                        Throughout my experience in software development, I've had the privilege of working alongside senior developers, talented teams,
                        and innovative startups that shaped my skillset and raised my standards for what expected of any software developer.<br /><br />
                        I take great pleasure in coding things from scratch and enjoy the process of bringing new ideas to life. 
                        However, I never neglect the importance of keeping my code clean, maintainable, and easy to understand.

                    </p>
                </AboutDescription>
                <SkillsDescribtion>
                    <h2>My Skills</h2>
                    <p>
                        Through my studies, I've gained a solid understanding of computer science concepts. In addition I have 
                        dedicated a lot of my free time to learn modern web development concepts and general porgramming skills. 
                    </p>
                    <p>
                        As a self-motivated learner, I've explored a variety of online resources, including Udemy. 
                        First I started with <b><a href="https://click.linksynergy.com/link?id=eW1gFvpqcmg&offerid=507388.625204&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fthe-web-developer-bootcamp%2F"
                        target="_blank" rel="noopener noreferrer">The Web Development Bootcamp</a></b> that gave me all the skills I need to start building websites from scratch. 
                        From there, I went on to complete the <b><a href="https://click.linksynergy.com/link?id=eW1gFvpqcmg&offerid=507388.705264&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Freact-redux%2F"
                        target="_blank" rel="noopener noreferrer">Modern React with Redux</a></b> course that helped me master all the fundamental concepts behind React and Redux applications.
                    
                    </p>
                </SkillsDescribtion>
                <SkillsContainer>
                    <SkillsColumn>
                        <li>HTML &#38; CSS</li>
                        <li>JavaScript</li>
                    </SkillsColumn>
                    <SkillsColumn>
                        <li>React.js</li>
                        <li>Gatsby</li>
                    </SkillsColumn>
                    <SkillsColumn>
                        <li>Redux</li>
                        <li>Styled-Components</li>
                    </SkillsColumn>
                    <SkillsColumn>
                        <li>Node.js</li>
                        <li>Git</li>
                    </SkillsColumn>
                </SkillsContainer>
                <SkillsDescribtion>

                </SkillsDescribtion>
            </AboutContainer>
        </Layout>

    );
}

export default About;