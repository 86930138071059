import styled from "styled-components";

export const AboutContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    margin: 0 auto;
    line-height: 1.8;

    h2{
        font-size: 34px; 
    }

    a{
        text-decoration: none;
        color: #1F1F1F;
    }

    @media (max-width: 800px) {
        width: 90%;
    }
`;

export const AboutDescription = styled.div`
    margin-bottom: 50px;
`;

export const SkillsDescribtion = styled.div`

`;

export const SkillsContainer = styled.div`
    display: flex;
    align-content: space-evenly;
    padding: 10px;
    
    @media (max-width: 800px) {
        flex-direction: column; 
    }
`;

export const SkillsColumn = styled.div`
    width: 25%;

    li {
        margin: 10px;
        font-weight: bold;
    }

    @media (max-width: 800px) {
        width: 100%;
    }
`;

export const Image = styled.img` 
    src: ${props => props.src};
    width: 300px;
    height: 300px;
    border-radius: 50%;
    margin: 0 auto;

    @media (max-width: 800px) {
        width: 250px;
        height: 250px;   
    }
`;